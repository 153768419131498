


































































import { Component, Vue } from "vue-property-decorator";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplateDashboard
} from "@/configuration";
import { mapState } from "vuex";
import API from "@/api";
import ReusableButton from "@/components/ReusableButton.vue";

@Component({
  metaInfo: {
    title: "Cambiar contraseña",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["setOpenMenuPanel"]),
	setOpenMenuPanel(){
		return this.$store.state.openMenuPanel;
	}
  },
  data() {
    return {
      canShow: true,
      btn: {
        clickable: false,
        loading: false,
        isOk: null,
        successText: "Contraseña actualizada",
        errorText: "Error al actualizar la contraseña"
      },
      passwords: {
        old: "",
        new: "",
        confirm: ""
      },
      show: {
        old: false,
        new: false,
        confirm: false
      },
      rules: {
        notEmpty: [(v: any) => !!v || "Este campo es requerido"],
        password: [
          (v: any) => !!v || "Este campo es requerido",
          (v: any) =>
            v.length >= 6 || "Contraseña tiene que ser mayor o igual a 6"
        ]
      },
      passwordsAreValid: false
    };
  },
  watch: {
    passwords: {
      handler(val) {
        // @ts-ignore
        if (this.$refs.form.validate()) {
          if (this.$data.passwords.new == this.$data.passwords.confirm) {
            this.$data.btn.clickable = true;
          } else {
            this.$data.btn.clickable = false;
          }
        } else {
          this.$data.btn.clickable = false;
        }
      },
      deep: true
    }
  },
  methods: {
    changePassword() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$data.btn.loading = true;
        API.changePassword(
          this.$data.passwords.old,
          this.$data.passwords.new,
          this.$data.passwords.confirm
        )
          .then(res => {
            this.$router.push({
              path: "/panel/estadisticas"
            });
            this.$data.btn.isOk = true;
            /* this.$data.passwords.old = ''
          this.$data.passwords.new = ''
          this.$data.passwords.confirm = ''*/
          })
          .catch(error => {
            this.$data.btn.isOk = false;
          });
      }
    }
  },
  components: {
    ReusableButton
  }
})
export default class ChangePassword extends Vue {}
